<template>
    <div v-if="!item_edit" class="row" v-bind:class="{ deleted: item_deleted }">
		<div class="col-3">
			{{item_desc}}
		</div>
		<div class="col-1">
			{{item_priority}}
		</div>
		<div class="col-2">
			{{item_date}}
		</div>			
		<div class="col-2">
			{{item_status}}
		</div>
		<div class="col-3">
			{{item_note}}
		</div>	
		<div class="col-1" style="text-align:center">
			<img src="@/assets/pen2.svg" class="ms-1" style="width: 15px;height: 15px;"  @click="activateEdit" >
		</div>		
    </div>
    <div v-if="item_edit" class="row">
		<div class="col-3">
			<input class="mr-1" type="text" v-model="item_desc">
		</div>
		<div class="col-1">
			<div class="form-check">
				<input class="form-check-input" type="radio"  :id="'hR_' + item_id" value="High" v-model="item_priority">
				<label class="form-check-label" :for="'hR_' + item_id">
					High
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" :id="'mR_' + item_id" value="Medium" v-model="item_priority">
				<label class="form-check-label" :for="'mR_' + item_id">
					Medium
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" :id="'lR_' + item_id" value="Low" v-model="item_priority">
				<label class="form-check-label" :for="'lR_' + item_id">
					Low
				</label>
			</div>
		</div>
		<div class="col-2">
			<input type="date"  class="datepicker" v-model="item_date"/>
		</div>			
		<div class="col-2">
			<div class="form-check">
				<input class="form-check-input" type="radio" :id="'dR_' + item_id" value="Done" v-model="item_status">
				<label class="form-check-label" :for="'dR_' + item_id">
					Done
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" :id="'iR_' + item_id" value="Ongoing" v-model="item_status">
				<label class="form-check-label" :for="'iR_' + item_id">
					Ongoing
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" :id="'nR_' + item_id" value="Not started"  v-model="item_status">
				<label class="form-check-label" :for="'nR_' + item_id">
					Not started
				</label>
			</div>
		</div>
		<div class="col-3">
			<input class="mr-1" type="text" v-model="item_note">
		</div>	
		<div class="col-1" style="text-align:center">
			<img src="@/assets/check.svg" class="ms-1" style="width: 15px;height: 15px;"  @click="applyEdit" >
			<img v-if="!item_deleted" src="@/assets/circle-xmark.svg" class="ms-3" style="width: 15px;height: 15px;"  @click="deleteItem" >
			<img v-if="item_deleted" src="@/assets/progress-arrows.svg" class="ms-3" style="width: 15px;height: 15px;"  @click="undoDelete">
		</div>		
    </div>	
</template>

<script>
    // import axios from "axios"
	// import Datepicker from '@vuepic/vue-datepicker';
	// import '@vuepic/vue-datepicker/dist/main.css'
    export default{
        name:'ToDoItem',
		components: {},
		emits: ["itemChange"],
		props: ['iid', 'idesc', 'ipriority','idate','istatus','inote'],
        data(){
            return {
				item_id: this.iid,
				item_desc: this.idesc,
				item_priority: this.ipriority,
				item_date: this.idate,
				item_status: this.istatus,
				item_note: this.inote,
				item_edit: false,
				date: new Date().toLocaleDateString('en-CA'),
				selectionDate:new Date().toLocaleDateString('en-CA'),
				item_deleted: false
            }
        },
		mounted() {
		},
        methods:{
			activateEdit: function(){
				this.item_edit = true;
			},
			applyEdit: function(){
				this.item_edit = false;
				let itemData = {"update_type":"update","item_info":{item_id:this.item_id, desc:this.item_desc, priority:this.item_priority, status:this.item_status, date:this.item_date, note:this.item_note},'item_id':this.item_id};
				this.$emit('itemChange', itemData)
			},
			deleteItem: function(){
				console.log("item deleted!")
				this.item_edit = false;
				this.item_deleted = true;
				let itemData = {"update_type":"delete","item_info":{item_id:this.item_id},'item_id':this.item_id};
				this.$emit('itemChange', itemData)		
			},
			undoDelete: function(){
				this.item_edit = false;
				this.item_deleted = false;
				let itemData = {"update_type":"undo_delete","item_info":{item_id:this.item_id},'item_id':this.item_id};
				this.$emit('itemChange', itemData)
			}
			// returnToDo: function(){
			// 	console.log('submitted')
			// }
		}
	}
</script>
<style>
.header{
	font-weight: bold;
	/* text-align: center; */
}
.deleted{
	color:rgb(255, 149, 0);
	text-decoration: line-through;
}
</style>