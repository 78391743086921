<script setup>
import { RouterView } from 'vue-router'
</script>

<template>
  <div style="background-color: white;">

    <NavBar />

    <div class="is-loading-bar has-test-centered"  v-bind:class="{'is-loading':$store.state.isloading}">
      <div class="lds-dual-ring">

      </div>
    </div>

    <RouterView />

  </div>

</template>

<script>
  import NavBar from '@/components/layout/NavBar.vue'
  import axios from 'axios';
  export default{
    name:'App',
    components:{
      NavBar
    },
    beforeCreate(){
      this.$store.commit('initializeStore')

      if(this.$store.state.token){
        axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
      }else{
        axios.defaults.headers.common['Authorization'] = ""
      }
    }
  }
</script>

<style>
@import '@/assets/base.css';

body{
  background-color: white;
  background-image: linear-gradient(#ffffff, #ffffff); 
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.is-loading-bar {
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    
    &.is-loading {
        height: 80px;
    }
}

</style>
