<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link to="/" class="navbar-item">
            <img src="../figures/small_logo.svg" width="40" height="40" alt="">
        </router-link>

        <div class="navbar-nav ml-auto">
                    <div class="buttons">
                        <template v-if="!$store.state.isAuthenticated">
                            <router-link to="/sign-up" class="button is-success"><strong>Sign up</strong></router-link>
                            <router-link to="/log-in" class="button is-info">Log in</router-link>
                        </template>

                        <template v-else>
                            <router-link to="/chatgpt" class="button" title="Go to To Do list">
                                <img class="m-0" src="../../assets/ChatGPT_logo.svg" style="width: 20px;height: 20px;">
                            </router-link>
                            <router-link to="/todo" class="button" title="Go to To Do list">
                                <img class="m-0" src="../../assets/todo.svg" style="width: 20px;height: 20px;">
                            </router-link>     
                            <router-link to="/my-account" class="button" title="Go to dictionary">
                                <img src="../../assets/spellcheck.svg" style="width: 20px;height: 20px;">
                                <!-- <font-awesome-icon icon="spell-check" class="icons mr-1 has-text-info"></font-awesome-icon> -->
                            </router-link>
                            <router-link to="/Password-change" class="button" title="Change password">
                                <img src="../../assets/key.svg" style="width: 20px;height: 20px;">
                                <!-- <font-awesome-icon icon="key" class="icons mr-1 has-text-primary"></font-awesome-icon> -->
                            </router-link>
                            <button @click="logout()" class="button"  title="Log out">
                            <img src="../../assets/logout.svg" style="width: 20px;height: 20px;">
                                <!-- <font-awesome-icon icon="right-from-bracket" class="icons mr-1 has-text-secondary"/> -->
                            </button>
                        </template>
                    </div>

        </div>
    </nav>
    <!-- <nav class="navbar navbar-light bg-light"> -->
        <!-- <div class="navbar-brand">
            <router-link to="/" class="navbar-item">
                <figure>
                    <img src="../figures/small_logo.svg" width="40">
                </figure>
                
            </router-link>
        </div> -->
        <!-- <div class="navbar-menu">
            <div class="navbar-end">
                <div class="navbar-item">
                    <div class="buttons">
                        <template v-if="!$store.state.isAuthenticated">
                            <router-link to="/sign-up" class="button is-success"><strong>Sign up</strong></router-link>
                            <router-link to="/log-in" class="button is-info">Log in</router-link>
                        </template>

                        <template v-else>
                            <router-link to="/dashboard/my-account" class="button is-info">My account</router-link>
                            <button @click="logout()" class="button is-danger">Log out</button>
                        </template>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- </nav> -->
</template>

<script>
    import axios from "axios"
    export default {
        name: 'NavBar',
        methods:{
            async logout(){
                await axios
                    .post('/api/v1/token/logout/')
                    .then(() => {
                        console.log('Logged out')
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token')
                this.$store.commit('removeToken')

                this.$router.push('/')
            }
        }        
    }
</script>
