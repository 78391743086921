<template>

        <div :style="indent" :class="labelClasses">

            <!-- two collapse/expansion icons -->
            <span class="label-wrapper" @click="toggleChildren">
                <img v-if="nds.length && !this.showChildren" class="ms-1 me-1" src="@/assets/plus-square.svg" style="width: 15px;height: 15px;">
                <img v-else-if="nds.length && this.showChildren" class="ms-1 me-1" src="@/assets/minus-square.svg" style="width: 15px;height: 15px;">
                <img v-else-if="!nds.length && !this.showChildren" class="ms-1 me-1" src="@/assets/minus-square-disable.svg" style="width: 15px;height: 15px;">

                <!-- <font-awesome-icon v-if="nds.length && !this.showChildren" class="icons mr-1" :icon="['fas', 'square-plus']"></font-awesome-icon>
                <font-awesome-icon v-else-if="nds.length && this.showChildren" class="icons mr-1 is-info" :icon="['fas', 'square-minus']" ></font-awesome-icon>
                <font-awesome-icon v-else="!nds.length && !this.showChildren" class="icons mr-1  has-text-light" :icon="['fas', 'square-minus']"></font-awesome-icon> -->
            </span>        
            <span class="mr-1" :style="[(isDeleted ? 'text-decoration:line-through; color:orange; ':''),(isDeletedForEver ? 'text-decoration:line-through; color:red':''),( !isDeleted && !isDeletedForEver ? 'text-decoration:none':'')]" v-show="!ndedit">
                <input class="ps-5 mr-1 mb-0" type="checkbox" :id="['hiercheck_' + nid]" @change="updateHierGroup"><span v-bind:style= "[isChecked ? {'color':'black'} : {'color':'green'}]">{{ndlbl}}</span> 
            </span>
            <input class="mr-1" v-show="ndedit" type="text" v-model="ndlbl" @keyup.enter="applyEdit(ndedit, ndlbl)">
            
            
            <!-- two icons for concept label edit -->
            <img v-if="ndlbl != 'root'" src="@/assets/pen2.svg" class="ms-1" v-show="!ndedit" @click="startEdit(ndedit)" style="width: 15px;height: 15px;">
            <img src="@/assets/check.svg" class="ms-1" v-show="ndedit" @click="applyEdit(ndedit, ndlbl)" style="width: 15px;height: 15px;">
            <!-- <font-awesome-icon v-if="ndlbl != 'root'" icon="pen" class="icons mr-1 has-text-info" v-show="!ndedit" @click="startEdit(ndedit)"/>
            <font-awesome-icon icon="check" class="icons mr-1 has-text-info" v-show="ndedit" @click="applyEdit(ndedit, ndlbl)"/> -->

            <!-- two icons for concept delete/reverse delete -->
            <a v-if="ndlbl != 'root' && !isDeleted" class="ms-1" @click="deleteConcept()" style="width: 15px;height: 15px;">pause</a>
            <a v-if="isDeleted" class="ms-1" @click="reverseConcept()" style="width: 15px;height: 15px;">unpause</a>            
            <img v-if="ndlbl != 'root' && !isDeletedForEver" src="@/assets/circle-xmark.svg" class="ms-1" @click="deleteConcepForEver()" style="width: 15px;height: 15px;">
            <img v-if="isDeletedForEver" src="@/assets/progress-arrows.svg" class="ms-1" @click="reverseConceptDeleteForEver()" style="width: 15px;height: 15px;">
            <img v-if="!isChecked" class="ms-1 me-0"  @click="reverseCheckStatus()" src="@/assets/greencircle.svg" style="width: 15px;height: 15px;">
            <img class="ms-1 me-0"  @click="addToCompare()" src="@/assets/compare.svg" style="width: 15px;height: 15px;">
            <!-- <font-awesome-icon v-if="ndlbl != 'root' && !isDeleted" icon="xmark" class="icons mr-1 has-text-danger" @click="deleteConcept()"/>
            <font-awesome-icon v-if="isDeleted" icon="arrow-rotate-left" class="icons mr-1 has-text-warning" @click="reverseConcept()"/> -->
            

            <!-- for concept clusters distribution visualization -->
            <!-- <font-awesome-icon v-if="ndlbl != 'root'" icon="chart-line" class="icons mr-1 has-text-secondary" @click="openModal = true"/> -->
            

            <!-- for adding child to the current concept -->
            <img v-if="!nds.length && !ndchild" src="@/assets/arrow-right.svg" class="ms-1" @click="addChild(ndchild)" style="width: 15px;height: 15px;"> 
            <!-- <font-awesome-icon v-if="!nds.length && !ndchild" icon="arrow-right" class="icons mr-1 has-text-primary" @click="addChild(ndchild)"/>  -->
            <span v-if="!nds.length && ndchild">
                <input v-model="indentedConcept" @keyup.enter="addConcept(indentedConcept)">
                <img src="@/assets/check.svg" class="ms-1" v-on:click="addIndentedConcept(indentedConcept)" style="width: 15px;height: 15px;">
                <!-- <font-awesome-icon icon="check" class="icons has-text-primary" v-on:click="addIndentedConcept(indentedConcept)"/> -->
                <div v-if="newIndented">
                    <NodeTreeHierarchy  :id=100 :depth="depth + 1" :pid="nid" :edtcnting="changing"></NodeTreeHierarchy>
                    <span v-if="nds.length && this.showChildren"><input :style="indent_twice" :class="labelClasses" v-model="addedConcept"  ><img src="@/assets/plus.svg" style="width: 15px;height: 15px;"  class="ms-1"  :style="indent_more" :class="labelClasses" v-on:click="addConcept(addedConcept)"></span>
                </div>
            </span>
            
        </div>

        <ul v-if="nds.length">
            <div v-if="showChildren">
                <NodeTreeHierarchy
                    v-for="(nd, ix) in nds" :key="ix"
                    :nid="nd" :depth="depth + 1" :pid="nid" :edtcnting="changing">
                </NodeTreeHierarchy>
                <span v-if="nds.length && this.showChildren"><input :style="indent_twice" :class="labelClasses" v-model="addedConcept"  @keyup.enter="addConcept(addedConcept)"><img src="@/assets/plus.svg" style="width: 15px;height: 15px;" :style="indent_more" :class="labelClasses" v-on:click="addConcept(addedConcept)"/></span>
            </div>
        </ul>
        <!-- <Modal v-show="openModal" :plotId='ndid' @close='openModal = false'></Modal> -->
</template>

<script>
// import Modal from "./Modal.vue";

export default {
    name: "NodeTreeHierarchy",
    components: {
        // "Modal":Modal,
    },    
    props: ['nid', 'depth', 'pid','edtcnting','tchild'],
    data: function(){
        return{
            ndid: this.nid,
            ndlbl: this.$store.state.hier_results.concepts[this.nid],
            ndedit: false,
            nds:this.$store.state.hier_results.tree[this.nid],
            showChildren: !this.tchild,
            ndchild: false,
            newIndented: false,
            addedConcept:'',
            indentedConcept:'',
            // coefs: this.$store.state.hier_results.nodes_params[this.nid],
            openModal:false,
            isCommon:this.$store.state.hier_results.nodes_common[this.nid],
            isShared:this.$store.state.hier_results.nodes_shared[this.nid],
            isDeleted: this.$store.state.hier_results.nodes_deleted[this.nid],
            isChecked: this.$store.state.hier_results.nodes_checked[this.nid],
            isDeletedForEver:false,
            changing: this.edtcnting
        };
    },
    methods:{
        addToCompare(){
            if(this.$store.state.compareList.length == 2){
                this.$store.state.compareList.shift()
            }
            this.$store.state.compareList.push(this.ndid);
            // console.log("------------", this.$store.state.compareList)
        },
        reverseCheckStatus(){
            this.isChecked = true;
            this.$store.state.hier_results.nodes_checked[this.nid] = true


            this.$store.state.hier_results_updated.nodes_checked[this.ndid] = true
        },
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        startEdit(ndedit){
            this.ndedit = !ndedit
        },
        applyEdit(ndedit){
            this.ndedit = !ndedit
            this.$store.state.hier_results.concepts[this.nid] = this.ndlbl
            this.isChecked = true
            this.$store.state.hier_results.nodes_checked[this.nid] = true

            this.$store.state.hier_results_updated.concepts[this.nid] = this.ndlbl
            // this.$store.state.hier_results_updated.nodes_checked[this.ndid] = true
        },
        addChild(ndchild){
            this.ndchild = !ndchild
        },
        applyChild(ndchild){
            this.ndchild = !ndchild
        },        
        addConcept(addedConcept){
            if(addedConcept !== ''){
                const newId = Object.keys(this.$store.state.hier_results.concepts).length+1;
                this.$store.state.hier_results.concepts[newId] = addedConcept;
                this.$store.state.hier_results.nodes_common[newId] = false
                this.$store.state.hier_results.nodes_deleted[newId] = false
                this.$store.state.hier_results.nodes_shared[newId] = false
                this.$store.state.hier_results.nodes_checked[this.ndid] = true
                // this.$store.state.hier_results.nodes_params[newId] = ""
                this.$store.state.hier_results.nodes_parent[newId] = this.ndid
                this.$store.state.hier_results.tree[this.ndid].push(newId)
                this.$store.state.hier_results.tree[newId] = []
                this.addedConcept = ''


                this.$store.state.hier_results_updated.concepts[newId] = addedConcept;
                this.$store.state.hier_results_updated.nodes_parent[newId] = this.ndid
                // this.$store.state.hier_results_updated.nodes_checked[this.ndid] = true
                






            }
        },
        addIndentedConcept(indentedConcept){
            if(typeof indentedConcept === 'undefined'){
                alert('Input value is undefined!');
                this.newIndented = false;
                this.ndchild = !this.ndchild
            }else{

                this.newIndented = true;
                // console.log(this.$store.state.hier_results.concepts)
                const newId = Object.keys(this.$store.state.hier_results.concepts).length+1;
                this.$store.state.hier_results.concepts[newId] = indentedConcept;
                this.$store.state.hier_results.nodes_common[newId] = false
                this.$store.state.hier_results.nodes_deleted[newId] = false
                this.$store.state.hier_results.nodes_shared[newId] = false
                this.$store.state.hier_results.nodes_checked[newId] = true
                // this.$store.state.hier_results.nodes_params[newId] = ""
                this.$store.state.hier_results.nodes_parent[newId] = this.ndid
                this.$store.state.hier_results.tree[this.ndid].push(newId)
                this.isChecked = true
                this.$store.state.hier_results.nodes_checked[this.ndid] = true
                this.$store.state.hier_results.tree[newId] = []
                this.showChildren = true
                this.indentedConcept = ''                
                // console.log(this.$store.state.hier_results.concepts)


                this.$store.state.hier_results_updated.concepts[newId] = indentedConcept;
                this.$store.state.hier_results_updated.nodes_parent[newId] = this.ndid
                // this.$store.state.hier_results_updated.nodes_checked[this.ndid] = true

            }
        },
        showConceptPlot(){
            // console.log('hi')
            this.showPlot = true
        },
        deleteConcept(){
            this.isDeleted = true
            this.$store.state.hier_results.nodes_deleted[this.ndid] = true
            this.isChecked = true
            this.$store.state.hier_results.nodes_checked[this.ndid] = true

            this.$store.state.hier_results_updated.nodes_deleted[this.ndid] = true
            // this.$store.state.hier_results_updated.nodes_checked[this.ndid] = true
        },
        reverseConcept(){
            this.isDeleted = false
            this.$store.state.hier_results.nodes_deleted[this.ndid] = false
            this.isChecked = true
            this.$store.state.hier_results.nodes_checked[this.ndid] = true



            this.$store.state.hier_results_updated.nodes_deleted[this.ndid] = false
        },
        deleteConcepForEver(){
            this.isDeletedForEver = true
            this.$store.state.hierresultsDeleted.push(this.ndid)
            this.isChecked = true
            this.$store.state.hier_results.nodes_checked[this.ndid] = true
            // console.log(this.$store.state.hierresultsDeleted)
            // this.$store.state.results.nodes_deleted[this.ndid] = true

            this.$store.state.hier_results_updated.hierresultsDeleted.push(this.ndid)
            // this.$store.state.hier_results_updated.nodes_checked[this.ndid] = true

        },
        reverseConceptDeleteForEver(){
            this.isDeletedForEver = false
            const index = this.$store.state.hierresultsDeleted.indexOf(this.ndid);
            this.$store.state.hierresultsDeleted.splice(index, 1)
            // console.log(this.$store.state.hierresultsDeleted)
            // this.$store.state.results.nodes_deleted[this.ndid] = false

            const index2 = this.$store.state.hier_results_updated.hierresultsDeleted.indexOf(this.ndid);
            this.$store.state.hier_results_updated.hierresultsDeleted.splice(index2, 1)

        },           
        updateHierGroup(){
            this.$store.commit('updateHierGroup',this.ndid)
            // console.log(this.$store.state.hiergroup)
        },
    },
    computed: {
        labelClasses() {
            return { 'has-children': this.nds }
        },
        indent() {
            return { transform: `translate(${this.depth * 20}px)` }
        },
        indent_twice() {
            return { transform: `translate(${this.depth * 20+15}px)` }
        },
        indent_more() {
            return { transform: `translate(${this.depth * 20+20}px)` }
        },
    },
    created: function(){
    }
};
</script>
<style>
.icons:hover, .icons:focus {
  text-decoration: none;
  cursor: pointer;
} 
</style>