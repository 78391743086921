<template>
    <div>
		<span class="row">
			
			<span class="col-12">
				<!-- {{labels}} -->
				<span v-for="lbl in labels" :key="lbl[0]">
					{{lbl[0]}} 
					<span v-if="lbl[1] || this.states.includes(lbl[0])"><button type="button" class="btn btn-outline-warning btn-sm p-0 ps-1 pe-1 m-0 me-2" @click="addConcept(lbl[0])">+</button> </span>
					<span v-else><button type="button" class="btn btn-outline-success btn-sm p-0 ps-1 pe-1 m-0 me-2" @click="addConcept(lbl[0])">+</button> </span>  
				</span>
			</span>

		</span>

    </div>
</template>

<script>
    import axios from "axios"

    export default{
        name:'AddConcept',
		components: {
			
		},    
		props: ["topic"],		
        data(){
            return {
				labels: this.topic,
				states: Array(),
            }
        },
		mounted() {
			this.getStates();
		},
        methods:{
			async addConcept(concept){
                let _this = this;
                await axios.get("/addchatgptconcept/"+concept).then(response => {
					if(response.status == 200){
						_this.states.push(concept);
					}
					
                })
			},
			getStates(){
				for(var l of this.labels){
					if(l[1]){
						this.states.push(l[0]);
					}
				}
			}

		}
	}
</script>