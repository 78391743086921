import { createStore } from "vuex";
// import { mapState } from "vuex";

export default createStore({
    state:{
        isLoading:false,
        isAuthenticated:false,
        token:'',
        results: {},
        group: new Set(),
        group_parent:'',
        group_root:true,
        hier_results: {},
        hiergroup: new Set(),
        hiergroup_parent:'',
        hiergroup_root:true,
        resultsDeleted: [],
        hierresultsDeleted: [],
        compareList: Array(),
        hier_results_updated: {concepts:Object(), nodes_parent:Object(), nodes_checked:Object(), nodes_deleted:Object(), hierresultsDeleted:Array() },
        syn_results_updated: {concepts:Object(), nodes_parent:Object(), nodes_checked:Object(), nodes_deleted:Object(), synresultsDeleted:Array() }
        // concepts:'',
        // nodes_parent:'',
        // nodes_rep:'',
        // nodes_common:'',
        // nodes_shared:'',
        // nodes_deleted:'',
        // nodes_params:'',
    },
    mutations:{
        initializeStore(state){
            if (localStorage.getItem('token')){
                state.token = localStorage.getItem('token')
                state.isAuthenticated = true
            }else{
                state.token = ''
                state.isAuthenticated = false
            }
        },
        setIsLoading(state, status){
            state.isLoading = status
        },
        setToken(state, token){
            state.token = token
            state.isAuthenticated = true
        },
        removeToken(state){
            state.token = ''
            state.isAuthenticated = false
        },
        setResults(state, results){
            state.results = results
        },
        setHierResults(state, results){
            state.hier_results = results
        },        
        updateGroup(state, ndid){
            
            if (state.group.has(ndid)){
                state.group.delete(ndid)
            }else{
                state.group.add(ndid)
            }
            if(state.group.size == 1){
                // state.group_parent = ndid
                state.group_parent = [...state.group][0]
            }else if(state.group.size == 0){
                state.group_parent = ''
            }
        },
        updateHierGroup(state, ndid){
            
            if (state.hiergroup.has(ndid)){
                state.hiergroup.delete(ndid)
            }else{
                state.hiergroup.add(ndid)
            }
            if(state.hiergroup.size == 1){
                state.hiergroup_parent = [...state.hiergroup][0]
            }else if(state.hiergroup.size == 0){
                state.hiergroup_parent = ''
            }
            // console.log(state.hiergroup, state.hiergroup_parent, ndid)
        },
        setGroupRoot(state, status){
            state.group_root = status
        },          
        setHierGroupRoot(state, status){
            state.hiergroup_root = status
        }, 

    },
    actions:{

    },
    modules:{

    }
})