<template>
    <div class="container">
		<div class="mt-4 p-3" style="border: 1px solid gray;border-radius: 5px;">
			<div class="row mt-2">
				<div class="col-2">Layer 1</div>
				<div class="col-2">Layer 2</div>
				<div class="col-2">Layer 3</div>
				<div class="col-2">Layer 4</div>
				<div class="col-2">Layer 5</div>
				<div class="col-2">Layer 6</div>
			</div>
		
			<div class="row mt-2">
				<div class="col-2">
					<select class="form-select" name="l1select" id="l1select" style="display: flex;" @input="l1Change($event)"> 
					<option></option>
					<option v-for="(name, index) in l1codes" :key="index" :value="index">
						{{ index }}-{{name}}
					</option>
					</select> 
				</div>
				<div class="col-2">
					<select class="form-select" name="l2select" id="l2select" style="display: flex;" @input="l2Change($event)"> 
					<option></option>
					<option v-for="(name, index) in l2codes" :key="index" :value="index">
						{{ index }}-{{name}}
					</option>
					</select> 
				</div>
				<div class="col-2">
					<select class="form-select" name="l3select" id="l3select" style="display: flex;" @input="l3Change($event)"> 
					<option></option>
					<option v-for="(name, index) in l3codes" :key="index" :value="index">
						{{ index }}-{{name}}
					</option>
					</select> 
				</div>
				<div class="col-2">
					<select class="form-select" name="l4select" id="l4select" style="display: flex;" @input="l4Change($event)"> 
					<option></option>
					<option v-for="(name, index) in l4codes" :key="index" :value="index">
						{{ index }}-{{name}}
					</option>
					</select> 
				</div>
				<div class="col-2">
					<select class="form-select" name="l5select" id="l5select" style="display: flex;" @input="l5Change($event)"> 
					<option></option>
					<option v-for="(name, index) in l5codes" :key="index" :value="index">
						{{ index }}-{{name}}
					</option>
					</select> 
				</div>
				<div class="col-2">
					<select class="form-select" name="l6select" id="l6select" style="display: flex;" @input="l6Change($event)"> 
					<option></option>
					<option v-for="(name, index) in l6codes" :key="index" :value="index">
						{{ index }}-{{name}}
					</option>
					</select> 
				</div>
			</div>
			<div class="row mt-2">
				<span style="line-height:34px;  text-align: right;">
					<button class="btn btn-info" @click="retrieveData">Retrieve Data</button>
					<button class="btn btn-warning ms-2" @click="callChatGPT">Send to ChatGPT</button>
				</span>

			</div>
		</div>


		<div class="mt-4 p-3" style="border: 1px solid gray;border-radius: 5px;">
		
			<div class="row mt-2">
				<div class="col-2">
					<input class="form-control" type="text" v-model="l1input">
				</div>
				<div class="col-2">
					<input class="form-control" type="text" v-model="l2input">
				</div>
				<div class="col-2">
					<input class="form-control" type="text" v-model="l3input">
				</div>
				<div class="col-2">
					<input class="form-control" type="text" v-model="l4input">
				</div>
				<div class="col-2">
					<input class="form-control" type="text" v-model="l5input">
				</div>
				<div class="col-2">
					<input class="form-control" type="text" v-model="l6input">
				</div>
			</div>
			<div class="row mt-2">
				<span style="line-height:34px;  text-align: right;">
					<button class="btn btn-success ms-2" @click="changeDB">Apply to DB</button>
				</span>

			</div>
		</div>
		<div class="card-header bg-light is-pulled-left p-2 m-0" style="position: absolute; left: 0; z-index: 10; opacity: 0.75;">
			<transition name="fade">
				<div v-if="message" style="background-color: aquamarine;">
					<div class=" p-2"> 
						The changes are applied successfully!
					</div>
				</div>
			</transition>                           
		</div>
		<div class="card-header bg-light is-pulled-left p-2 m-0" style="position: absolute; right: 0; z-index: 10; opacity: 0.75;">
			<transition name="fade">
				<div v-if="clipboardMessage" style="background-color: aquamarine;">
					<div class=" p-2"> 
						The message is copied to clipboard!
					</div>
				</div>
			</transition>                           
		</div>

		<div class="mt-4 p-3" style="border: 1px solid gray;border-radius: 5px;">
			<div class="row mt-2" style="height:45vh;">
				<div class="col-6" style="height:100%;">
					<div class="row ms-1 fw-bold">
						Titles
					</div>
					<hr class="m-0"/>
					<div class="row" style="height:95%; overflow-y:scroll;overflow-x:hidden;">
						<ol class="ms-4 me-2">
							<li v-for="ttl in titles" :key="ttl">
								{{ ttl }}
							</li>
						</ol>

					</div>
				</div>
				<div class="col-6" style="height:100%;">
					<div class="row">
						<div class="row ms-0 fw-bold">Prompt:</div>
						<div class="col-6 me-0 pe-0">
							<div class="col-12 ms-1 pe-3">
								<textarea class="col-12 p-1" name="text" placeholder="Enter prompt..." style="height:10vh;resize: none;border-radius: 5px;" @input="updateTextArea($event.target.value)"></textarea>
							</div>
							<div class="col-12 ms-1 pe-3 mb-3">
								<button type="button" class="col-12 btn btn-secondary btn-sm pe-1" @click="addClipboard">Add to clipboard</button>
							</div>							
						</div>
						<div class="col-6 ms-0 ps-0">
							<textarea class="col-12 p-1" name="text" placeholder="Enter summary..." style="height:13.5vh;resize: none;border-radius: 5px;" v-model="summaryInput"></textarea>
						</div>

					</div>
					<div class="row ms-0 fw-bold">
						ChatGPT output:
					</div>
					<hr class="m-0"/>
					<div class="row" >
						<div class="col-3">
							Main term:
						</div>
						<div class="col-9 fw-bold ps-0">
							{{label}}<button v-if="label!=''" type="button" class="btn btn-primary btn-sm p-0 ps-1 pe-1 m-0 ms-3" @click="moveLabel">↑</button>
						</div>
					</div>
					<div class="row" style="height:48%; overflow-y:scroll;overflow-x:hidden;">
						<div class="col-3">
							Other terms:
						</div>
						<div class="col-9">
							<div class="concept" v-for="topic in topics" :key="topic">
								<AddConcept :topic="topic"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


    </div>
</template>

<script>
    import axios from "axios";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.defaults.xsrfCookieName = "csrftoken"
	import AddConcept from "./AddConcept.vue";

    export default{
        name:'ChatGPT',
		components:{"AddConcept":AddConcept},
        data(){
            return {
				l1input:'',
				l2input:'',
				l3input:'',
				l4input:'',
				l5input:'',
				l6input:'',
				l1codes: null,
				l2codes: null,
				l3codes: null,
				l4codes: null,
				l5codes: null,
				l6codes: null,
				titles: null,
				label:'',
				topics:null,
				message: false,
				clipboardMessage: false,
				clipboardtxt:'',
				concatenated:'',
				summaryInput:'',
            }
        },
		computed:{ 

		},
		mounted() {
			this.getLayer1()
		},
        methods:{
			updateTextArea(value){
				this.clipboardtxt = value;
			},
			addClipboard(){
				if(this.concatenated != ''){
					let cliptxt = this.clipboardtxt+ "\n\n\"" + this.concatenated+"\"";
					navigator.clipboard.writeText(cliptxt);
					this.clipboardMessage = true
					setTimeout(() => {  this.clipboardMessage = false; }, 2000);
				}

			},
			async changeDB(){
				let l1lbl = document.getElementById("l1select").value;
				let l2lbl = document.getElementById("l2select").value;
				let l3lbl = document.getElementById("l3select").value;
				let l4lbl = document.getElementById("l4select").value;
				let l5lbl = document.getElementById("l5select").value;
				let l6lbl = document.getElementById("l6select").value;
				let newlbls = Object();
				newlbls[l1lbl] = this.l1input;
				newlbls[l2lbl] = this.l2input;
				newlbls[l3lbl] = this.l3input;
				newlbls[l4lbl] = this.l4input;
				newlbls[l5lbl] = this.l5input;
				newlbls[l6lbl] = this.l6input;
				newlbls['summary'] = [l5lbl, this.summaryInput];
                const formData = {
                    updated_entries: newlbls,
                }
                
                axios.post("/updatelayers", formData).then(response =>{
					if(response.status == 200){
						this.message = true
						setTimeout(() => {  this.message = false; }, 2000);
					}

                })
			},
			async retrieveData(){
                let _this = this;
				let node_code = document.getElementById("l6select").value;
				let url = "/retrieveData/"+node_code;
                await axios.get(url).then(response => {
                    _this.titles = response['data']['data'];
					_this.concatenated = Object.values(_this.titles).join(' ');
                })
			},
			async callChatGPT(){
                let _this = this;
				let node_code = document.getElementById("l6select").value;
				let url = "/chatgpt/"+node_code;
                await axios.get(url).then(response => {
                    let dta = response['data']['data'];
					_this.label = dta['label']
					_this.topics = dta['topics']
					
                })
			},
			moveLabel(){
				this.l6input = this.label;
			},
            async getLayer1(){
                let _this = this;
                await axios.get("/getl1/").then(response => {
                    _this.l1codes = response['data']['data']
                })
            },
            async getLayer2(node_code){
                let _this = this;
                await axios.get("/getl2/"+node_code).then(response => {
                    _this.l2codes = response['data']['data']
                })
            },
            async getLayer3(node_code){
                let _this = this;
                await axios.get("/getl3/"+node_code).then(response => {
                    _this.l3codes = response['data']['data']
                })
            },
            async getLayer4(node_code){
                let _this = this;
                await axios.get("/getl4/"+node_code).then(response => {
                    _this.l4codes = response['data']['data']
                })
            },
            async getLayer5(node_code){
                let _this = this;
                await axios.get("/getl5/"+node_code).then(response => {
                    _this.l5codes = response['data']['data']
                })
            },
            async getLayer6(node_code){
                let _this = this;
                await axios.get("/getl6/"+node_code).then(response => {
                    _this.l6codes = response['data']['data'];
		_this.summaryInput = response['data']['summary'];
                })
            },
			l1Change(event){
				if(event.target.value != ''){
					
					this.getLayer2(event.target.value);
					this.l1input = this.l1codes[event.target.value];
					this.l2input = '';
					this.l3input = '';
					this.l4input = '';
					this.l5input = '';
					this.l6input = '';
					this.l2codes = null;
					this.l3codes = null;
					this.l4codes = null;
					this.l5codes = null;
					this.l6codes = null;
					
				}
				else{
					this.l1input = '';
				}
			},
			l2Change(event){
				if(event.target.value != ''){
					
					this.getLayer3(event.target.value);
					this.l2input = this.l2codes[event.target.value];
					this.l3input = '';
					this.l4input = '';
					this.l5input = '';
					this.l6input = '';
					this.l3codes = null;
					this.l4codes = null;
					this.l5codes = null;
					this.l6codes = null;
				}
				else{
					this.l2input = '';
				}
			},
			l3Change(event){
				if(event.target.value != ''){
					
					this.getLayer4(event.target.value);
					this.l3input = this.l3codes[event.target.value];
					this.l4input = '';
					this.l5input = '';
					this.l6input = '';
					this.l4codes = null;
					this.l5codes = null;
					this.l6codes = null;
				}
				else{
					this.l3input = '';
				}
			},
			l4Change(event){
				if(event.target.value != ''){
					
					this.getLayer5(event.target.value);
					this.l4input = this.l4codes[event.target.value];
					this.l5input = '';
					this.l6input = '';
					this.l5codes = null;
					this.l6codes = null;
				}
				else{
					this.l4input = '';
				}
			},
			l5Change(event){
				if(event.target.value != ''){
					
					this.getLayer6(event.target.value);
					this.l5input = this.l5codes[event.target.value];
					this.l6input = '';
					this.l6codes = null;
				}
				else{
					this.l5input = '';
				}
			},
			l6Change(event){
				if(event.target.value != ''){
					this.l6input = this.l6codes[event.target.value];
				}
				else{
					this.l6input = '';
				}
			},
		}
	}
</script>
<style>
.header{
	font-weight: bold;
	/* text-align: center; */
}
.concept:hover {
  background-color: #f0f0f5;
}
</style>
