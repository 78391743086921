<template>
  <!-- <div class='modalContainer'>
    <div class='modal-header'>
      <h3>
        {{modalData.title || "ModalTitle"}}
      </h3>
    </div>
    <div class='modal-body'>
		I can not get modal component pop up on the screen. I imported modal component into a another component, but modal does not show when button is clicked. If I make inital value true it is seen on the sceen but I want to show it whenever I want .
	</div>
    <div class='modal-footer'>
      <button type="button" class="btn btn-primary" @click="close">{{modalData.closeButtonText || "Close modal"}}</button>
    </div>
  </div>-->

  <div class="container m-2 p-2">
    <!-- Modal content -->
    <div
      class="p-0"
      style="width: 460px; background-color:white;border: 1px solid #888; margin: auto; border-radius: 10px;"
    >
      <div class="mt-2 pb-4 pe-3">
        <span
          class="close is-pulled-right"
          style="font-weight: bold; color: orange; font-size: 20px;"
          @click="close"
        >x
          <!-- <font-awesome-icon class="close" icon="circle-xmark" /> -->
        </span>
      </div>
      <div class="box mt-3 p-1">
        <input
          class="form-check-input ml-2 mr-1 pt-1"
          type="checkbox"
          v-model="isCommon"
          @click="changeStateCommon()"
        />
        <label class="form-check-label">Is it common?</label>
        <input
          class="form-check-input ml-4 mr-1 pt-1"
          type="checkbox"
          v-model="isShared"
          @click="changeStateShared()"
        />
        <label class="form-check-label">Is it shared?</label>
      </div>
      <div :id="'arc' + nodeid"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  
  name:'ModalView',
  props: ['plotId','plotdata']
  // modalData: {
  //   type: Object,
  //   required: true
  // }
  ,
  data() {
    return {
      nodeid: this.plotId,
      isCommon: this.$store.state.results.nodes_common[this.plotId],
      isShared: this.$store.state.results.nodes_shared[this.plotId],
      params1: '',
      params1_cnt: 0,
      disc_cnt: '',
      diagramIncluded:this.plotdata
    }
  },
  mounted() {
    
    if(this.diagramIncluded){
      
      this.params1 = this.$store.state.results.nodes_params[this.plotId]
      this.params1_cnt = this.$store.state.results.nodes_params_cnt[this.plotId]
      this.disc_cnt = this.$store.state.results.nodes_disc_cnt[this.plotId]
      this.plotDist();
    }
    // console.log(this.common, this.shared);
  },
  computed:{

  },
  methods: {
    close() {
      this.$emit('close')
    },
    changeStateCommon() {
      this.$store.state.results.nodes_common[this.nodeid] = !this.isCommon
      // console.log(this.$store.state.concepts_common);
    },
    changeStateShared() {
      this.$store.state.results.nodes_shared[this.nodeid] = !this.isShared
      this.$emit("changeSharedStatus")
      // console.log('insideModal', !this.isShared)
      // console.log(this.$store.state.concepts_shared);
    },
    plotDist() {

    // let xTemp, yTemp
    if (this.params1 !== '' && this.params1 !== '""') {
      var width = 400;
      var height = 200;
      var margin = ({top: 20, right: 30, bottom: 30, left: 60});

      var svg = d3
        .select("#arc" + this.nodeid)
        .append("svg")
        .attr("viewBox", [0, 0, width, height]);
      console.log(this.nodeid,this.params1)
      var yData = this.params1.split('|').map(i=>Number(i)*100);
      
      var yData_cnt = this.params1_cnt.split('|').map(i=>Number(i));
      var yDdisc_cnt = this.disc_cnt.split('|').map(i=>Number(i));
      var xData = Array.from(new Array(yData.length), (x, i) => i);
      var nData = xData.length;
      var maxData = yData[yData.length - 1]

      const isAllZero = yData.every(item => item === 0);

      if(isAllZero){
          var textX = (width - margin.left - margin.right)/ 2.0 + margin.left;
          var textY = (height - margin.bottom - margin.top) / 2.0 + margin.top;         
          svg.append('g')
              .attr('transform', 'translate(' + textX + ', ' + textY + ')')
              .append("text")
              .attr('text-anchor', 'middle')
              .text("#pids/total is < 0.001 for all clusters")
              .style("font-size", "small")     
      }else{
        var barwidth = (width - margin.right - margin.left)/(nData * 1.25)
        var bardist = barwidth * 0.25

        // console.log(xData, yData, nData, maxData);
        var x = d3.scaleBand()
              // .domain([0, nData])
              .range([margin.left, width - margin.right])
        var y = d3.scaleLinear()
            .domain([0, maxData])
            .range([height - margin.bottom, margin.top]) 
        var xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x))
        var yAxis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y))         


        svg.append("g")
            .call(xAxis);

        svg.append("g")
            .call(yAxis);   


      svg.selectAll("rect")
          .data(xData)
          .enter()
          .append("rect")
          .attr("x", d=> margin.left + d * (barwidth + bardist))
          .attr("y", d=> (margin.top + (height - margin.top - margin.bottom)*(maxData - yData[d])/maxData))
          .attr("width", barwidth)
          .attr("height", d=>(yData[d])*(height - margin.top - margin.bottom)/maxData)
          .attr("fill", "Cyan");



      var yaxisLabelX = 20;
      var yaxisLabelY = (height - margin.bottom - margin.top) / 2.0 + margin.top;
      
      svg.append('g')
          .attr('transform', 'translate(' + yaxisLabelX + ', ' + yaxisLabelY + ')')
          .append("text")
          .attr('text-anchor', 'middle')
          .attr('transform', 'rotate(-90)')
          .text("#pids/total (%)")
          .style("font-size", "small")

      var xaxisLabelX = (width - margin.left - margin.right)/ 2.0 + margin.left;
      var xaxisLabelY = height - 10 ;          
      svg.append('g')
          .attr('transform', 'translate(' + xaxisLabelX + ', ' + xaxisLabelY + ')')
          .append("text")
          .attr('text-anchor', 'middle')
          .text("clusters")
          .style("font-size", "small")     

      svg.append('g').selectAll('text')
          .data(xData)
          .enter()
          .append("text")
          .attr('text-anchor', 'start')
          .text(d=>yData_cnt[d] +' (' + d3.format(",.0f")(yDdisc_cnt[d]).replace(',', ' ').replace(/,/g, ',').replace(' ', ',')+')')

          .style("font-size", "12px")      
          .attr('transform', d=>{
                var txt_height = ((margin.top + (height - margin.top - margin.bottom)*(maxData - yData[d])/maxData)-5)
                var txt_width = (margin.left + d * (barwidth + bardist) + barwidth/2)+2
                if (txt_height<50){
                  txt_height += 100
                  txt_width -= 0
                }
                return 'translate( '+txt_width+' , '+txt_height+'),'+ 'rotate(-90)';})
          .attr('x', 0)
          .attr('y', 0)
          .style('fill', 'black')
          // .attr("x", d=> margin.left + d * (barwidth + bardist) + barwidth/2)
          // .attr("y", d=> (margin.top + (height - margin.top - margin.bottom)*(maxData - yData[d])/maxData)-5)    

          

      }
       


      // var x = d3.scaleBand()
      //     .range([ 20, 430 ]);

      // svg.append("g")
      //     .attr("transform", "translate(20," + 430 + ")")
      //     .call(d3.axisBottom(x))

      // Add Y axis
      // var y = d3.scaleBand()
      //     .domain([0, maxData])
      //     .range([ maxData, 0]);
      // svg.append("g")
      //     .call(d3.axisLeft(y));
 
    }

      // const w = 430
      // const h = 200
      // const padding = 30

      
      // const pointNum = Math.ceil(this.$store.state.results.nodes_cluster_cnt[this.plotId])

      // //   let svg = d3.select(DOM.svg(w, h))



      // // Clip path
      // let clipPath = svg.append('clipPath')
      //   .attr('id', 'chart-area')
      //   .append('rect')
      //   .attr('x', padding)
      //   .attr('y', padding)
      //   .attr('width', w - 2 * padding)
      //   .attr('height', h - 2 * padding)

      // let xTemp, yTemp
      // if (this.$store.state.results.nodes_params[this.nodeid] !== '' && this.$store.state.results.nodes_params[this.nodeid] !== '""') {
      //   var params_arr = this.params1.split('|')
      //   var f = parseFloat(params_arr[0]);
      //   var e = parseFloat(params_arr[1]);
      //   var d = parseFloat(params_arr[2]);
      //   var c = parseFloat(params_arr[3]);
      //   var b = parseFloat(params_arr[4]);
      //   var a = parseFloat(params_arr[5]);
      //   // Generate points of `y = 4sin(2x)`
      //   let dataset_sin = []
      //   for (let i = 0; i <= pointNum; i++) {
      //     xTemp = i
      //     yTemp = f * Math.pow(xTemp, 5) + e * Math.pow(xTemp, 4) + d * Math.pow(xTemp, 3) + c * Math.pow(xTemp, 2) + b * Math.pow(xTemp, 1) + a
      //     if (yTemp < 1 && yTemp > 0) {
      //       dataset_sin.push([xTemp, yTemp])
      //     }

      //   }
      // const xDomain = Math.min(dataset_sin.length,xTemp)+1 ;
      // let xScale = d3.scaleLinear([0, xDomain], [padding, w - padding])
      // let yScale = d3.scaleLinear([1, 0], [padding, h - padding])

      //   let line_sin = d3.line()
      //     .x(d => xScale(d[0]))
      //     .y(d => yScale(d[1]))

      //   svg.append('path')
      //     .datum(dataset_sin)
      //     .attr("clip-path", "url(#chart-area)")
      //     .attr('fill', 'none')
      //     .attr('stroke', '#663399')
      //     .attr('stroke-width', 2)
      //     .attr('d', line_sin)


      //   let xAxis = d3.axisBottom(xScale)
      //   let yAxis = d3.axisLeft(yScale)

      //   svg.append('g')
      //     .attr('transform', `translate(0,${h - padding})`)
      //     .call(xAxis)
      //   svg.append('g')
      //     .attr('transform', `translate(${padding},0)`)
      //     .call(yAxis)
      // }
    }
  }
}
</script>

<style scoped>
/* .modalContainer{
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
	background-color:white;
	border-color: white;
	opacity: 1 !important;
}

.modal-header{
	background-color: white;
	border-color: white;
}
.modal-body{
	background-color: white;
	border-color: white;
}
.modal-footer{
	border-color: white;
    position: fixed;
    width: 100%;
    bottom: 0;
} */

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
/* .close {
  color: #ff0000;
  font-size: 28px;
  font-weight: bold;
} */

.close:hover,
.close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}
.vertical{
transform: rotate(90deg);
}
</style>