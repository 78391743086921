<template>
    <div class="container">
        <div class="section">
            <div class="columns">
                <div class="column is-4 is-offset-4">
                    <h1 class="title">Sign Up</h1>

                    
                    <form @submit.prevent="submitForm">
                        <div class="field">
                            <label>Email*<div style="font-size:x-small">Sign up should be through WashU email!</div></label>
                            <div class="control">
                                <input type="email" name="email" class="input" v-model="username">
                            </div>
                        </div>

                        <div class="field">
                            <label>Password</label>
                            <div class="control">
                                <input type="password" name="password1" class="input" v-model="password1">
                            </div>
                        </div>

                        <div class="field">
                            <label>Repeat password</label>
                            <div class="control">
                                <input type="password" name="password2" class="input" v-model="password2">
                            </div>
                        </div>


                        <div class="notification is-danger" v-if="errors.length">
                            <ul>
                                <li class="p-0 m-0" v-for="error in errors" v-bind:key="error">{{ error }} </li>
                            </ul>
                        </div>



                        <div class="field">
                            <div class="control">
                                <button class="button is-success">Submit</button>
                            </div>
                        </div>

                    </form>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    

    import {toast} from 'bulma-toast'
    export default{
        name:'SignUp',
        data(){
            return {
                username:'',
                password1: '',
                password2: '',
                errors: [],
                isWUSTL:false
            }
        },
        methods:{
            async submitForm(){
                this.$store.commit('setIsLoading', true)
                this.errors = []
                if (this.username === ''){
                    this.errors.push('The username is missing!')
                }
                if (this.password1 === ''){
                    this.errors.push('The password is too short!')
                }
                if (this.password1 !== this.password2){
                    this.errors.push('The password does not match!')
                }     
                if (!this.username.includes("@wustl.edu")){
                    this.errors.push('Sign up should be through WashU email!!')
                }   
                if (!this.errors.length){
                    const formData = {
                        username: this.username,
                        email: this.username,
                        password: this.password1
                    }
                    await axios
                    .post('/api/v1/users/', formData)
                    .then(response => {
                        toast({
                            message: 'An email was sent to your email, please click the link provided in the email!',
                            type:'is-success',
                            pauseonHover:true,
                            duration: 4000,
                            position: 'buttom-right'
                        })
                        console.log(response['data'])
                    // this.$router.push('/log-in')
                    })
                    .catch(error => {
                        if(error.response){
                            for (const property in error.response.data){
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }
                        } else if (error.message){
                            console.log(error.message)
                            this.errors.push('Something went wrong, please try again!')
                        }
                    })
                    this.$store.commit('setIsLoading', false)
                }                          
            }
        }
    }
</script>