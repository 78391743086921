<template>
    <div class="container">
		<div class="row new-item mt-3 p-2">
			<div class="row mt-5">
				<div class="col-3 header">
					Item Description
				</div>
				<div class="col-1 header">
					Priority
				</div>
				<div class="col-2 header">
					Date
				</div>			
				<div class="col-2 header">
					Status
				</div>
				<div class="col-3 header">
					Note
				</div>					
				<div class="col-1 header" style="text-align:center">
					Add Item
				</div>									
			</div>		
			<hr>


			<div class="row mt-3">
				<div class="col-3">
					<input class="mr-1" type="text" v-model="item_desc">
				</div>
				<div class="col-1">
					<div class="form-check">
						<input class="form-check-input" type="radio" name="priority" id="highRadio" value="High" v-model="item_priority">
						<label class="form-check-label" for="highRadio">
							High
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" name="priority" id="mediumRadio" value="Medium" v-model="item_priority">
						<label class="form-check-label" for="mediumRadio">
							Medium
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" name="priority" id="lowRadio" value="Low" v-model="item_priority">
						<label class="form-check-label" for="lowRadio">
							Low
						</label>
					</div>
				</div>
				<div class="col-2">
					<input type="date"  class="datepicker" v-model="selectionDate"/>
				</div>			
				<div class="col-2">
					<div class="form-check">
						<input class="form-check-input" type="radio" name="status" id="doneRadio" value="Done" v-model="item_status">
						<label class="form-check-label" for="doneRadio">
							Done
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" name="status" id="inProgressRadio" value="Ongoing" v-model="item_status">
						<label class="form-check-label" for="inProgressRadio">
							Ongoing
						</label>
					</div>
					<div class="form-check">
						<input class="form-check-input" type="radio" name="status" id="notStarted" value="Not started"  v-model="item_status">
						<label class="form-check-label" for="notStarted">
							Not started
						</label>
					</div>
				</div>
				<div class="col-3">
					<input class="mr-1" type="text" v-model="item_note">
				</div>	
				<div class="col-1" style="text-align:center">
					<img src="@/assets/plus.svg" class="ms-1 p-1" style="width: 25px;height: 25px; cursor: pointer; border: 1px solid rgb(0, 130, 0); border-radius: 50%;"  @click="addItem" >
				</div>		
			</div>

		</div>	

		<div class="row mt-5">
			<div class="col-3 header">
				Item Description
			</div>
			<div class="col-1 header">
				Priority
			</div>
			<div class="col-2 header">
				Date
			</div>			
			<div class="col-2 header">
				Status
			</div>
			<div class="col-3 header">
				Note
			</div>					
			<div class="col-1 header">
				Edit
			</div>									
		</div>		
		<hr class="mt-0 pb-0">		
		<div class="row todo-list" v-for="item in todo_items" :key="item.id">
			<ToDoItem :iid="item[0]" :idesc="item[1]" :ipriority="item[2]" :idate="item[3]" :istatus="item[4]" :inote="item[5]" @itemChange="updateItem"/>
		</div>

		<div class="row mt-5">
			<div class="col-11">

			</div>
			<div class="col-1">
				<form @submit.prevent="updateToDos" class="form-inline">
					<button class="btn btn-success btn-sm  mr-2 mb-0">Submit</button>
				</form>				
			</div>
		</div>
		<div v-if="unsaved" class="row" style="color:red;">
			<div class="col-3 unsaved-div">
				You have unsaved changes!
			</div>
			<div class="col-9">

			</div>
		</div>
    </div>
</template>

<script>
    import axios from "axios"
	import ToDoItem from "./ToDoItem.vue"

    export default{
        name:'ToDo',
		components:{"ToDoItem":ToDoItem},
        data(){
            return {
				todo_items: Array(),
				item_desc:'',
				item_priority:"High",
				selectionDate:new Date().toLocaleDateString('en-CA'),
				item_status: "Not started",
				item_note: '',
				item_updates: Object(),
				items_deleted: Array(),
            }
        },
		computed:{ 
			unsaved:{
				get:function(){
					if(this.items_deleted.length > 0 || Object.keys(this.item_updates).length > 0){
						return true;
					}else{
						return false;
					}
				}
			}


		},
		mounted() {
			this.loadItems();
		},
        methods:{
			loadItems: async function(){
				let _this = this;
				var url = "/gettodos/"
				await axios.get(url).then(response => {
					_this.todo_items = response["data"]["todos"];
					console.log(_this.todo_items);
				})
			},			
			addItem: function(){
				if(this.item_desc === ''){
					alert("Please add an item description!")
				}else{
					const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)
					let item_id = hashCode(this.item_desc)
					this.todo_items.push([item_id, this.item_desc, this.item_priority, this.selectionDate, this.item_status, this.item_note]);
					this.item_updates[item_id] = {"item_id":item_id,"old_id":item_id, "desc": this.item_desc, "priority":this.item_priority, "date":this.selectionDate, "status":this.item_status, "note":this.item_note, "new":true};					
					this.item_desc = "";
					this.item_priority = "High";
					this.selectionDate = new Date().toLocaleDateString('en-CA');
					this.item_status = "Not started";
					this.item_note = "";
					console.log(item_id);
				}
				
			},
			updateToDos: async function(){
				// let _this = this;
				var url = "/updatetodos/"
				console.log(this.item_updates);
                const formData = {
                    updated_todos: this.item_updates,
					deleted: this.items_deleted
                }				
				await axios.post(url, formData).then(response =>{
					console.log(response);
					this.item_updates = Object();
					this.items_deleted = Array()
				}).catch(error=>{
                    console.log(error)
                })
			},
			updateItem: function(childInfo){
				let item_id = childInfo['item_id']
				let item_info = childInfo['item_info'];
				let update_type = childInfo['update_type'];
				if(update_type=='update'){
					this.item_updates[item_id] = childInfo['item_info'];
				}else if(update_type=='delete'){
					this.items_deleted.push(item_id);
					console.log(update_type,this.items_deleted);
				}else if(update_type=='undo_delete'){
					var index = this.items_deleted.indexOf(item_id);
					if (index > -1) {
						this.items_deleted.splice(index, 1);
					}
					console.log(update_type);
				}else{
					console.log('There is no functions defined for ', update_type)
				}
				console.log(update_type, item_info)
			}
		}
	}
</script>
<style>
.header{
	font-weight: bold;
	/* text-align: center; */
}
.todo-list{
	max-height: 50vh;
	overflow-y: auto;
}
.new-item{
	border: 2px solid rgb(12, 99, 3);
	border-radius: 0%;
}
.unsaved-div{
	border: 1px solid rgb(0, 0, 0);
	border-radius: 10%;
	background-color: antiquewhite;
	padding: 10px;
	z-index: 100;	
}
</style>