<template>
    <div class="container">
        <div class="section">
            <div class="columns is-multiline">
                <div class="column is-12">
                    <h1 class="title">Dashboard</h1>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name:'DashBoard'
    }
</script>