<template>
    <div style=" overflow-y: hidden; background-color: white;">
        <div class="container-fluid"  >
            <div class="row mb-0" style=" overflow-y: hidden; height: 93vh;">
                <div class="col" style="height: 100%;">
                    <strong class="card-header bg-light mt-2 mb-2">Concepts Hierarchy</strong>
                    <div class="container">
                        <div class="row">
                            <form @submit.prevent="submitHierForm" class="form-inline  mt-1">
                                <div class="input-group">
                                    <div class="col-2 mr-0 pr-0" style="line-height:38px;">
                                        <label>Concept:</label>
                                    </div>
                                    <div class="col-4 mr-2 pl-0" style="line-height:58px;">
                                        <select class="form-select" name="hier-concept-select" v-model="hierConceptSelect" style="display: flex;" @change="onHierChange"> 
                                                <option>All</option>
                                                <option v-for="(name, index) in dd_hierconcepts" :key="index">
                                                    {{ name }}
                                                </option>
                                        </select> 
                                    </div>
                                    <div class="col-4 mr-2 pr-0" style="line-height:38px;">
                                        <input class="form-control" type="text" placeholder="Concept..." name="syn-concept-input" v-model="hierConceptInput">
                                    </div>                 
                                    <div class="col-1 mr-0 pr-0" style="line-height:34px;">
                                        <button type="submit" class="btn btn-warning">Go</button>
                                    </div>                              
                                </div>
                            </form>                                               
                        </div>
                    </div>
                    <hr class="mt-2"/>
                    <div v-if="hierDataLoaded" style="overflow-y: scroll;overflow-x: scroll; height: 71%" :key="editCnt">
                        <NodeTreeHierarchy v-if="editCnt" :nid="'G0'" :depth="1"></NodeTreeHierarchy>
                    </div>      
                    <span v-if="!hierDataLoaded" style="overflow-y: hidden;overflow-x: hidden; height: 71%; display: inline-block;"></span>
                    <span v-if="hierDataLoaded">
                    <hr class="mt-2 mb-1">
                        <button class="btn btn-secondary btn-sm mr-2" @click="resetHierFn">Reset</button>                    
                        <button class="btn btn-secondary btn-sm mr-2" @click="regroupHierFn">Regroup</button>
                        <button v-if="compareList.length > 1" class="btn btn-primary btn-sm mr-2" title="Conferences" data-bs-toggle="modal" data-bs-target="#conferences" @click="showIntersection">Compare</button>
                        <div v-if="hierDataLoaded"  class="buttons is-pulled-right m-0 p-0">
                            <button class="btn btn-danger btn-sm mr-2 mb-0" @click="discardHierFn">Discard</button>
                            <form @submit.prevent="returnHierForm" class="form-inline m-0 p-0">
                                <button class="btn btn-success btn-sm mr-2 mb-0">Submit</button>
                            </form>
                        </div>     
                    </span>
                    <hr  class="mt-1 mb-2" v-if="hierDataLoaded"/>
                    <div class="card-header bg-light is-pulled-left p-2 m-0" style="position: absolute; left: 0; z-index: 10; opacity: 0.75;">
                        <transition name="fade">
                            <div v-if="elementVisible" style="background-color: aquamarine;">
                                <div class=" p-2"> 
                                    The changes are applied successfully!
                                </div>
                            </div>
                        </transition>  
                        <transition name="fade">
                            <div v-if="comparePossible" style="background-color: aquamarine;">
                                <div class=" p-2"> 
                                    For camparison at least two concepts should be selected!
                                </div>
                            </div>
                        </transition>
                    </div>                 
              
                 </div>

<!-- ----------------------------------- SECOND Column ----------------------------------- -->
                 
                <div class="col" style="height: 100%;">
                    <strong class="card-header bg-light mt-2 mb-2">
                        Concepts' Synonyms 
                    </strong>
                    <div class="container">
                        <div class="row">
                            <form @submit.prevent="submitSynForm" class="form-inline mt-1 me-0 pe-0" autocomplete="off">
                                <div class="input-group p-0 m-0">
                                    <div class="col-1 me-2 pr-0" style="line-height:38px;">
                                        <label>Concept:</label>
                                    </div>
                                    <!-- <div class="col-3 mr-2 ps-0" style="line-height:58px;">
                                        <select class="form-select" name="syn-concept-select" v-model="synConceptSelect" style="display: flex;" @change="onChange"> 
                                                <option v-for="(name,index) in dd_hierconcepts" :key="index">
                                                    {{ name }}
                                                </option>
                                        </select> 
                                    </div> -->
                                    <div class="col-7 me-2 pr-0" style="line-height:38px;">
                                        <input class="form-control" type="text" placeholder="Concept..." name="syn-concept-input" v-model="synConceptInput">
                                        <!-- <input class="form-control" type="text" placeholder="Concept..." name="syn-concept-input" v-model="synConceptInput" v-on:keyup="synInputChange"> -->
                                        <div v-if="isSynSearchItems" class="autocomplete p-0 m-0" style="width: 100%;">
                                            <div class="autocomplete-items p-0 m-0" style="width: 100%;clear: both;">
                                                <div v-for="(item, index) in synSearchItems" :key="index" name="item" class="ps-1 pt-0 pb-0 m-0" style="width: 100%; max-width: 100%; margin: 0;padding: 0;font-size: small; display: inline-block;white-space: nowrap;" @click="updateSynInput(item)">
                                                            {{item}}
                                                </div>            
                                            </div>                            
                                        </div>                                        
                                    </div>  
                                    <div class="col-1 mr-0 pr-0" style="line-height:34px;">
                                        <button type="submit" class="btn btn-warning">Go</button>
                                        
                                    </div>           
                                    <div class="col-1 mt-1">
                                            <input class="form-check-input" type="checkbox" id="exactsearch" value="exactsearch" v-model="exactSearch" style="vertical-align:middle;display: inline-block;">
                                            <span class="ms-1 mt-1"  style="vertical-align:middle;display: inline-block;">Exact</span>                                           
                                    </div>                                                                
                                    <div class="col-1 mt-1 ms-3">
                                            <input class="form-check-input" type="checkbox" id="diagram" value="diagram" v-model="diagramIncluded" style="vertical-align:middle;display: inline-block;">
                                            <span class="ms-1 mt-1" style="vertical-align:middle;display: inline-block;">Plots</span>                                           
                                    </div>                                                                                                        
                                </div>
                            </form>
                                               
                        </div>
                    </div>
                    <hr class="mt-2"/>
                    <div v-if="synDataLoaded" style="overflow-y: scroll;overflow-x: hidden; height: 71%;" :key="editCnt">
                        <NodeTreeSyns v-if="editCnt" :nid="'G0'" :depth="1" :plotdata="diagramIncluded"></NodeTreeSyns>
                    </div>   
                    <span v-if="!synDataLoaded" style="overflow-y: hidden;overflow-x: hidden; height: 71%; display: inline-block;"></span>
                    <span v-if="synDataLoaded">
                        <hr class="mt-2 mb-1">
                        <form @submit.prevent="" class="form-inline" autocomplete="off">
                            <div class="input-group">
                                <button class="btn btn-secondary btn-sm mr-2" @click="resetFn">Reset</button>                    
                                <button class="btn btn-secondary btn-sm mr-2 me-5" @click="regroupFn">Regroup</button>
                                    <select class="form-select form-select-sm mr-2" name="download-concept-select" v-model="downloadConceptSelect" style="display: flex;"> 
                                            <option>Genes</option>
                                            <option>Diseases</option>
                                            <option v-for="(name, index) in dd_hierconcepts" :key="index">
                                                {{ name }}
                                            </option>
                                    </select>
                                    <button  type="button" class="btn btn-light btn-sm me-5" @click="downloadData">
                                        <img class="p-0" src="@/assets/download.svg" style="width: 20px;height: 20px;">
                                        <!-- <font-awesome-icon icon="circle-down" class="icons has-text-primary"></font-awesome-icon> -->
                                    </button>
                                    <div v-if="synDataLoaded"  class="buttons is-pulled-right  m-0 p-0">
                                        <button class="btn btn-danger btn-sm  mr-2 mb-0" @click="discardFn">Discard</button>
                                        <form @submit.prevent="returnSynForm" class="form-inline">
                                            <button class="btn btn-success btn-sm  mr-2 mb-0">Submit</button>
                                        </form>
                                    </div>                                    
                            </div>
                        </form>
                    </span>
                    <hr  class="mt-1 mb-2" v-if="synDataLoaded"/>
                    <div class="card-header bg-light is-pulled-left p-2 m-0" style="position: absolute; left: 0; z-index: 10; opacity: 0.75;">
                        <transition name="fade">
                            <div v-if="synelementVisible" style="background-color: aquamarine;">
                                <div class=" p-2"> 
                                    The changes are applied successfully!
                                </div>
                            </div>
                        </transition>                           
                    </div>
                 </div>   



            <div class="modal fade" id="conferences" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="conferencesLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="conferencesLabel">Papers' Intersection</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div v-if="compareData_loaded==true"  id="intersecting-papers" style="display:flex; height: auto;">
                                <div class="container" style="width:100%">
                                    <div class="row">
                                        <div class="col-5 ms-2">
                                            {{compare_c1_desc}}
                                        </div>
                                        <div class="col-5 ms-2">
                                            {{compare_c2_desc}}
                                        </div>                                        
                                    </div>
                                    <hr class="mt-0">
                                    <div class="row">
                                        <div class="col-5 ms-2" style="font-weight:bold">
                                            {{compare_c1_val}}
                                        </div>
                                        <div class="col-5 ms-2" style="font-weight:bold">
                                            {{compare_c2_val}}
                                        </div>                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-5 ms-2" style="font-weight:bold; color:red; font-size:34px">
                                            {{compare_c1_perc}}
                                        </div>
                                        <div class="col-5 ms-2" style="font-weight:bold; color:red; font-size:34px">
                                            {{compare_c2_perc}}
                                        </div>                                        
                                    </div>
                                    <hr class="">
                                </div>
                            </div>
                            <div v-if="compareData_loaded==false">
                                The conceps selected has not been examined yet!
                            </div>

                        </div>
                    </div>
                </div>
            </div>  



















            </div>           
        </div>                       
    </div>
</template>

<script>
    import axios from "axios"
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    axios.defaults.xsrfCookieName = "csrftoken"
    // import Tree from "./Tree.vue";
    // import Node from "./NodeTree.vue";
    import NodeTreeSyns from "./NodeTreeSyns.vue";
    import NodeTreeHierarchy from "./NodeTreeHierarchy.vue";
    import * as d3 from 'd3';
    import { mapState } from 'vuex'
    export default{
        name:'MyAccount',
        components: {
            // "Tree":Tree, 
            "NodeTreeSyns": NodeTreeSyns,
            "NodeTreeHierarchy": NodeTreeHierarchy,
            // "Node":Node
        },
        data() {
            return {
                ndid: 'G0',
                results: [],
                message: '',
                tree:'',
                hiertree:'',
                synConceptSelect: '',
                synConceptInput: '',
                hierConceptSelect: '',
                hierConceptInput: '',
                errors: [],
                synDataLoaded:false,
                hierDataLoaded:false,
                edit:false,
                editCnt: 1,
                dd_hierconcepts: {},
                toggle: true,
                elementVisible: false,
                synelementVisible:false,
                synSearchItems: [],
                isSynSearchItems: false,
                downloadConceptSelect:'',
                diagramIncluded:false,
                exactSearch: true,
                compareItems: Array(),
                svg: null,
                comparePossible: false,
                compareList: this.$store.state.compareList,
                compareData:null,
                compare_c1_val:null,
                compare_c2_val:null,
                compare_c1_perc:null,
                compare_c2_perc:null,
                compare_c1_desc:null,
                compare_c2_desc:null,
                compareData_loaded:false,
            }
        },
        mounted() {
            this.getHierConceptDdData();
            // this.svg = d3
            //     .select("#intersecting-papers")
            //     .append("svg")
            //     .attr("viewBox", [0, 0, 800, 200]);              
        },
        computed(){
            // comapreItems= this.$store.state.compareList
            
            // this.comapreItems = this.$store.state.compareList
            mapState(['compareList'])
            console.log(this.compareList)
          
        },
        methods: {
            // async getHierConceptDdData(){
            //     var vm = this;
            //     await axios.get('/hierconcepts/loaded').then(function(response){
            //         vm.dd_hierconcepts = response['data']['tree']
            //     })
            //     this.resetHierFn()
            // },
            plotCompareVenn(data){
                
                var vals = [data[0][1],data[1][1],data[2][1]];
                var descs = [data[0][0],data[1][0],data[2][0]];
                var x1, x2;
                x1 = 10;
                x2 = 60;                
                // if(vals[2] > 0){
                //     x1 = 35;
                //     x2 = 65;
                //     x3 = (x1+x2)/2
                // }else{
                //     x1 = 25;
                //     x2 = 75;
                //     x3 = (x1+x2)/2
                // }                      
                // this.svg.append('circle')
                //     .attr('cx', x1+'%')
                //     .attr('cy', '40%')
                //     .attr('r', 200)
                //     .style('fill', 'blue')
                //     .style('opacity', '0.3');
                // this.svg.append('circle')
                //     .attr('cx', x2+'%')
                //     .attr('cy', '40%')
                //     .attr('r', 150)
                //     .style('fill', 'red')
                //     .style('opacity', '0.3');
                    
                // this.svg
                //     .append('text')
                //     .attr('x', (x1)+'%')
                //     .attr('y', '50')
                //     .attr("dy", ".35em")
                //     .text(descs[0])
                //     .attr("class","compare-concept")

                this.svg.append("foreignObject")
                    .attr('x', (x1)+'%')
                    .attr('y', '20')                
                    .attr("width", "300px")
                    .attr("height", "40px")
                    .append("xhtml:div")
                    .style("color", "#000")
                    .style("text-align", "left")
                    .style("width", "100%")
                    .style("height", "100%")
                    .style("font-size", "24px")
                    .html(descs[0])

                this.svg.append('text')
                    .attr('x', (x1)+'%')
                    .attr('y', '100')
                    .attr("dy", ".35em")
                    .text(d3.format(",.0f")(vals[0]).replace(',', ' ').replace(/,/g, ',').replace(' ', ','))
                    .style("font-size", "24px")
                    .style("font-weight", "bold");
                
                this.svg.append('text')
                    .attr('x', (x1)+'%')
                    .attr('y', '150')
                    .attr("dy", ".35em")
                    .text(Math.round(vals[2]/vals[0]*100)+"%")
                    .style("font-size", "36px")
                    .style("fill","red");                     
                
                // if(vals[2] >0){
                //     this.svg.append('text')
                //         .attr('x', x3+'%')
                //         .attr('y', '70%')
                //         .attr('r', 150)
                //         .attr("dy", ".35em")
                //         .text(descs[2])
                //         .style("font-size", "24px");  
                //     this.svg.append('text')
                //         .attr('x', (x3+2)+'%')
                //         .attr('y', '74%')
                //         .attr('r', 150)
                //         .attr("dy", ".35em")
                //         .text(d3.format(",.0f")(vals[2]).replace(',', ' ').replace(/,/g, ',').replace(' ', ',')+" "+vals[2]/vals[0], vals[2]/vals[1])
                //         .style("font-size", "24px"); 
                // }

                    
                
                // this.svg.append('text')
                //     .attr('x', (x2)+'%')
                //     .attr('y', '50')
                //     .attr("dy", ".35em")
                //     .text(descs[1])
                //     .attr("class","compare-concept")
                this.svg.append("foreignObject")
                    .attr('x', (x2)+'%')
                    .attr('y', '20')                
                    .attr("width", "300px")
                    .attr("height", "40px")
                    .append("xhtml:div")
                    .style("color", "#000")
                    .style("text-align", "left")
                    .style("width", "100%")
                    .style("height", "100%")
                    .style("font-size", "24px")
                    .html(descs[1])


                this.svg.append('text')
                    .attr('x', (x2)+'%')
                    .attr('y', '100')
                    .attr("dy", ".35em")
                    .text(d3.format(",.0f")(vals[1]).replace(',', ' ').replace(/,/g, ',').replace(' ', ','))
                    .style("font-size", "24px")
                    .style("font-weight", "bold");
                this.svg.append('text')
                    .attr('x', (x2)+'%')
                    .attr('y', '150')
                    .attr("dy", ".35em")
                    .text(Math.round(vals[1]/vals[0]*100)+"%")
                    .style("font-size", "36px")
                    .style("fill","red");                       
            },
            async showIntersection(){
                if(this.$store.state.compareList.length == 2){
                    let _this = this;
                    this.synDataLoaded = false;
                    this.isSynSearchItems = false
                    this.synSearchItems = []
                    // const compareConcepts = {'concepts':this.$store.state.compareList}
                    await axios.get("/hierCompare/",{params: {concepts: this.$store.state.compareList}}).then(response => {
                            _this.compareData = response['data']['data']
                            if(_this.compareData.length == 3){
                                // div.innerHTML = ''

                                // _this.plotCompareVenn(_this.compareData)
                                var vals = [_this.compareData[0][1],_this.compareData[1][1],_this.compareData[2][1]];
                                var descs = [_this.compareData[0][0],_this.compareData[1][0],_this.compareData[2][0]];


                                _this.compare_c1_val = vals[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                _this.compare_c2_val = vals[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                _this.compare_c1_perc = Math.round(vals[2]/vals[0]*100)+"%"
                                _this.compare_c2_perc = Math.round(vals[2]/vals[1]*100)+"%"
                                _this.compare_c1_desc = descs[0]
                                _this.compare_c2_desc = descs[1]
                                _this.compareData_loaded = true;  




                            }else{
                                _this.compareData_loaded = false;                                 
                                // div.innerHTML = 'The conceps selected has not been examined yet!'
                            }
                            // console.log(_this.compareData)
                            
                            })
                }else{
                    console.log('not sent')
                }
                if (this.$store.state.compareList.length == 2){
                    console.log("not sent")
                }else{
                    this.comparePossible = true
                    setTimeout(() => {  this.comparePossible = false; }, 2000);                    
                }          
            },
            async getHierConceptDdData(){
                let _this = this;
                await axios.get("/hierconcepts/loaded").then(response => {
                    _this.dd_hierconcepts = response['data']['tree']
                        
                })    
                //this.dd_hierconcepts = ['Biology','Chemistry','Earth Sciences','Engineering','Experimental Medicine','Health Sciences','Medicine','Multidisciplinary','Physics','Physics|Chemistry','Psychology','Sociology']                
                this.resetHierFn()
            },



            async submitSynForm(){
                console.log(this.diagramIncluded, this.exactSearch)
                // if(this.synConceptInput.match(/^[\"0-9a-z]+.*[0-9a-z\"]+$/) && this.synConceptInput.length > 2){
                if(this.synConceptInput.length >= 2){
                    this.synDataLoaded = false;
                    this.isSynSearchItems = false
                    this.synSearchItems = []
                    await axios.get("/concepts/newsearch",{params:{plot:this.diagramIncluded, exact:this.exactSearch, queryparams:this.synConceptInput.trim()}}).then(response => {
                            this.synDataLoaded = true;
                            this.$store.commit('setResults', response['data'])
                            })
                }else{
                    console.log('not sent')
                }
                this.resetFn()
            },
            returnSynForm(){
                const formData = {
                    // concepts:this.$store.state.results,
                    // deletedConcepts:this.$store.state.resultsDeleted,
                    // diagramIncluded:this.diagramIncluded
                    updated_entries: this.$store.state.syn_results_updated,
                }
                
                axios.post("/concepts/newData", formData).then(() =>{
                    this.synelementVisible = true
                    setTimeout(() => {  this.synelementVisible = false; }, 2000);
                    // this.submitSynForm()
                    // this.resetFn()
                    this.$store.state.resultsDeleted = []
                    this.$store.state.group.clear()
                    this.$store.state.group_parent = ''
                    this.$store.state.syn_results_updated = {concepts:Object(), nodes_parent:Object(), nodes_checked:Object(), nodes_deleted:Object(), synresultsDeleted:Array() }
                }).catch(error=>{
                    console.log(error)
                })
                this.resetFn()
            },



            async submitHierForm(){
                this.hierDataLoaded = false;
                await axios.get("/hierconcepts/"+this.hierConceptInput.trim()).then(response => {
                        this.hierDataLoaded = true;
                        // console.log(response['data'])
                        this.$store.commit('setHierResults', response['data'])
                        
                        })
                this.resetHierFn()
            },
            returnHierForm(){
                const formData = {
                    // concepts:this.$store.state.hier_results,
                    // deletedConcepts:this.$store.state.hierresultsDeleted,
                    updated_entries: this.$store.state.hier_results_updated,
                }
                axios.post("/hierconcepts/newData", formData).then(() =>{
                    this.elementVisible = true
                    setTimeout(() => {  this.elementVisible = false; }, 2000);
                    // this.submitHierForm()
                    this.$store.state.hierresultsDeleted = []
                    this.$store.state.hiergroup.clear()
                    this.$store.state.hiergroup_parent = ''  
                    this.$store.state.hier_results_updated = {concepts:Object(), nodes_parent:Object(), nodes_checked:Object(), nodes_deleted:Object(), hierresultsDeleted:Array() }                  
                }).catch(error=>{
                    console.log(error)
                })
                this.resetHierFn()
            },
            onChange() {
                this.synConceptInput = this.synConceptSelect
            },
            onHierChange() {
                this.hierConceptInput = this.hierConceptSelect
            },
            discardFn(){
                this.synDataLoaded = false;
            },
            discardHierFn(){
                this.hierDataLoaded = false;
            },            
            regroupFn(){
                console.log(this.$store.state.group)
                if(this.$store.state.group.size > 1){
                    this.toggle = false
                    const parent = this.$store.state.group_parent;
                    
                    this.$store.state.group.forEach(child =>{
                        document.getElementById("check_"+child).checked = false;
                        if(child != parent){
                            const prev_parent = this.$store.state.results.nodes_parent[child]
                            this.$store.state.results.nodes_parent[child] = parent
                            this.$store.state.results.tree[parent].push(child)
                            this.$store.state.results.tree[prev_parent].splice(this.$store.state.results.tree[prev_parent].indexOf(child), 1)


                            this.$store.state.syn_results_updated.nodes_parent[child] = parent
                        }
                    })
                }
                this.$store.state.group.clear()
                this.$store.state.group_parent = ''
                this.editCnt++;
                this.$store.state.group.forEach(child =>{
                        document.getElementById("check_"+child).checked = false;
                })                
            },
            resetFn(){
                this.$store.state.group.forEach(child =>{
                        document.getElementById("check_"+child).checked = false;
                })
                this.$store.state.group.clear()
                this.$store.state.group_parent = ''
                this.$store.state.syn_results_updated = {concepts:Object(), nodes_parent:Object(), nodes_checked:Object(), nodes_deleted:Object(), synresultsDeleted:Array() }
            },
            regroupHierFn(){
                if(this.$store.state.hiergroup.size > 1){
                    this.toggle = false
                    const parent = this.$store.state.hiergroup_parent;
                    this.$store.state.hier_results.nodes_checked[parent] = true;
                    // this.$store.state.hier_results_updated.nodes_checked[parent] = true
                    this.$store.state.hiergroup.forEach(child =>{
                        document.getElementById("hiercheck_"+child).checked = false;
                        if(child != parent){
                            this.$store.state.hier_results.nodes_checked[child] = true;
                            const prev_parent = this.$store.state.hier_results.nodes_parent[child]
                            this.$store.state.hier_results.nodes_parent[child] = parent
                            this.$store.state.hier_results.tree[parent].push(child)
                            this.$store.state.hier_results.tree[prev_parent].splice(this.$store.state.hier_results.tree[prev_parent].indexOf(child), 1)




                            this.$store.state.hier_results_updated.nodes_parent[child] = parent
                            // this.$store.state.hier_results_updated.nodes_checked[child] = true



                        }
                    })
                }
                this.$store.state.hiergroup.clear()
                this.$store.state.hiergroup_parent = ''
                this.editCnt++;       
                this.$store.state.hiergroup.forEach(child =>{
                        document.getElementById("hiercheck_"+child).checked = false;
                })
            },
            resetHierFn(){
                this.$store.state.hiergroup.forEach(child =>{
                        document.getElementById("hiercheck_"+child).checked = false;
                })
                this.$store.state.hiergroup.clear()
                this.$store.state.hiergroup_parent = ''
                this.$store.state.hier_results_updated = {concepts:Object(), nodes_parent:Object(), nodes_checked:Object(), nodes_deleted:Object(), hierresultsDeleted:Array() }
            },
            async synInputChange(){
                this.isSynSearchItems = false
                this.synSearchItems = []
                if (this.synConceptInput !== ''){
                    await axios.get("/synSearch/"+this.synConceptInput).then(response => {
                            this.isSynSearchItems = true;
                            this.synSearchItems = response['data']
                    })
                }
            },
            updateSynInput(item){
                this.synConceptInput = item
                this.isSynSearchItems = false
            },
            downloadData(){
                if (this.downloadConceptSelect !== ''){
                    axios.get("/download/"+this.downloadConceptSelect, {
                                    responseType: "arraybuffer"
                                }).then(response => {
                                    const url = window.URL.createObjectURL(
                                new Blob([response.data], {type: "application/csv"})
                            );
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `file.xlsx`);

                            document.body.appendChild(link);
                            link.click();
                            window.URL.revokeObjectURL(url);
                            link.remove();
                    });
                }
                this.resetFn();
                this.resetHierFn();
            }
        }
    }
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.autocomplete {
    position: relative;
    /* margin: 0;
    padding: 0;   */
}
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    /* border-bottom: none;
    border-top: none; */
    z-index: 99;
    /* top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0; */
}
.autocomplete-items div {
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
    background-color: #e9e9e9;
}
.autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
}
.compare-concept{
    max-width : 30px;
    word-wrap: break-word;
    font-size: 80px;
    color: blue;
}
</style>
