<template>
    <div class="container ">
      <section class="hero is-fullheight">
        <div class="hero-body has-text-centered">
          <div class="container">
            <!-- <div class="columns is-centered">
                    <img src="../components/figures/logo.svg" width="400">
            </div> -->
            <h1 class="is-size-1 has-text-weight-bold">Dictionary</h1>
          </div>
        </div>
      </section>
  </div>
</template>