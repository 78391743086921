<template>

        <div :style="indent" :class="labelClasses">

            <!-- two collapse/expansion icons -->
            <span class="label-wrapper" @click="toggleChildren">
                <img v-if="nds.length && !this.showChildren" class="ms-1 me-1" src="@/assets/plus-square.svg" style="width: 15px;height: 15px;">
                <img v-else-if="nds.length && this.showChildren" class="ms-1 me-1" src="@/assets/minus-square.svg" style="width: 15px;height: 15px;">
                <img v-else-if="!nds.length && !this.showChildren" class="ms-1 me-1" src="@/assets/minus-square-disable.svg" style="width: 15px;height: 15px;">

                <!-- <font-awesome-icon v-if="nds.length && !this.showChildren" class="icons mr-1" :icon="['fas', 'square-plus']"></font-awesome-icon>
                <font-awesome-icon v-else-if="nds.length && this.showChildren" class="icons mr-1 is-info" :icon="['fas', 'square-minus']" ></font-awesome-icon>
                <font-awesome-icon v-else="!nds.length && !this.showChildren" class="icons mr-1  has-text-light" :icon="['fas', 'square-minus']"></font-awesome-icon>-->
            </span>         
            <span class="mr-1" :style="[(isDeleted ? 'text-decoration:line-through; color:orange; ':''),(isDeletedForEver ? 'text-decoration:line-through; color:red':''),( !isDeleted && !isDeletedForEver ? 'text-decoration:none':'')]" v-show="!ndedit">
                <input class="ps-5 mr-1 mb-0" type="checkbox" :id="['check_' + nid]" @change="updateGroup">{{ndlbl}}
                <span v-if="ndlbl != 'root'">
                    ({{cluster_cnt}}) <span v-if="firstChild">({{ndType}}) </span><span v-if="isShared" style="font-style: italic; color: green">shared</span>
                </span>
            </span>
            <input class="mr-1" v-show="ndedit" type="text" v-model="ndlbl"  @keyup.enter="applyEdit(ndedit, ndlbl)">
            
            
            <!-- two icons for concept label edit -->
            
            <img v-if="ndlbl != 'root'" src="@/assets/pen2.svg" class="ms-1" v-show="!ndedit" @click="startEdit(ndedit)" style="width: 15px;height: 15px;">
            <img src="@/assets/check.svg" class="ms-1" v-show="ndedit" @click="applyEdit(ndedit, ndlbl)" style="width: 15px;height: 15px;">

            <!-- <font-awesome-icon v-if="ndlbl != 'root'" icon="pen" class="icons mr-1 has-text-info" v-show="!ndedit" @click="startEdit(ndedit)"/>
            <font-awesome-icon icon="check" class="icons mr-1 has-text-info" v-show="ndedit" @click="applyEdit(ndedit, ndlbl)"/> -->

            <!-- two icons for concept delete/reverse delete -->
            <a v-if="ndlbl != 'root' && !isDeleted" class="ms-1" @click="deleteConcept()" style="width: 15px;height: 15px;">pause</a>
            <a v-if="isDeleted" class="ms-1" @click="reverseConcept()" style="width: 15px;height: 15px;">unpause</a>
            <img v-if="ndlbl != 'root' && !isDeletedForEver" src="@/assets/circle-xmark.svg" class="ms-1" @click="deleteConcepForEver()" style="width: 15px;height: 15px;">
            <img v-if="isDeletedForEver" src="@/assets/progress-arrows.svg" class="ms-1" @click="reverseConceptDeleteForEver()" style="width: 15px;height: 15px;">

            <!-- <font-awesome-icon v-if="ndlbl != 'root' && !isDeleted" icon="xmark" class="icons mr-1 has-text-danger" @click="deleteConcept()"/>
            <font-awesome-icon v-if="isDeleted" icon="arrow-rotate-left" class="icons mr-1 has-text-warning" @click="reverseConcept()"/> -->
            

            <!-- for concept clusters distribution visualization -->
            <img v-if="ndlbl != 'root' && coefs.length > 1 && diagramIncluded " src="@/assets/chart.svg" class="ms-1" @click="openModal = true" style="width: 20px;height: 20px;">
            <img v-if="ndlbl != 'root' && diagramIncluded && coefs.length < 3" src="@/assets/chart_notav.svg" class="ms-1" style="width: 20px;height: 20px;">
            <!-- <font-awesome-icon v-if="ndlbl != 'root'" icon="chart-line" class="icons mr-1 has-text-secondary" @click="openModal = true"/> -->
            

            <!-- for adding child to the current concept -->
            <img v-if="!nds.length && !ndchild" src="@/assets/arrow-right.svg" class="ms-1" @click="addChild(ndchild)" style="width: 15px;height: 15px;">             
            <!-- <font-awesome-icon v-if="!nds.length && !ndchild" icon="arrow-right" class="icons mr-1 has-text-primary" @click="addChild(ndchild)"/>  -->
            <span v-if="!nds.length && ndchild">
                <input v-model="indentedConcept" @keyup.enter="addConcept(indentedConcept)">
                <img src="@/assets/check.png" class="ms-1" v-on:click="addIndentedConcept(indentedConcept)" style="width: 15px;height: 15px;">
                <!-- <font-awesome-icon icon="check" class="icons has-text-primary" v-on:click="addIndentedConcept(indentedConcept)"/> -->
                <div v-if="newIndented">
                    <NodeTreeSyns  :id=100 :depth="depth + 1" :pid="nid" :edtcnting="changing" :plotdata="diagramIncluded"></NodeTreeSyns>
                    <span v-if="nds.length && this.showChildren"><input :style="indent_twice" :class="labelClasses" v-model="addedConcept">
                        <img src="@/assets/plus.png" style="width: 15px;height: 15px;"  class="ms-1"  :style="indent_more" :class="labelClasses" v-on:click="addConcept(addedConcept)">
                        <!-- <font-awesome-icon icon="plus" class="icons  has-text-primary" :style="indent_more" :class="labelClasses" v-on:click="addConcept(addedConcept)"/> -->
                    </span>
                </div>
            </span>
            
        </div>

        <ul v-if="nds.length">
            <div v-if="showChildren">
                <NodeTreeSyns
                    v-for="(nd, ix) in nds" :key="ix"
                    :nid="nd" :depth="depth + 1" :pid="nid" :edtcnting="changing" :plotdata="diagramIncluded">
                </NodeTreeSyns>
                <span v-if="nds.length && this.showChildren"><input :style="indent_twice" :class="labelClasses" v-model="addedConcept" @keyup.enter="addConcept(addedConcept)">
                    <img src="@/assets/plus.png" style="width: 15px;height: 15px;" :style="indent_more" :class="labelClasses" v-on:click="addConcept(addedConcept)"/>
                    <!-- <font-awesome-icon icon="plus" class="icons has-text-primary" :style="indent_more" :class="labelClasses" v-on:click="addConcept(addedConcept)"/> -->
                </span>
            </div>
        </ul>
        <Modal v-show="openModal" :plotId='ndid'  :plotdata="diagramIncluded" @close='openModal = false'  @changeSharedStatus="updateSharedStatus" style="z-index:4"></Modal>
</template>

<script>
import Modal from "./Modal.vue";
export default {
    name: "NodeTreeSyns",
    components: {
        "Modal":Modal
    },    
    props: ['nid', 'depth', 'pid','edtcnting','tchild','plotdata'],
    data: function(){
        return{
            ndid: this.nid,
            ndlbl: this.$store.state.results.concepts[this.nid],
            ndedit: false,
            nds:this.$store.state.results.tree[this.nid],
            showChildren: !this.tchild,
            ndchild: false,
            newIndented: false,
            addedConcept:'',
            coefs: '',
            openModal:false,
            isCommon:this.$store.state.results.nodes_common[this.nid],
            isShared:this.$store.state.results.nodes_shared[this.nid],
            isDeleted: this.$store.state.results.nodes_deleted[this.nid],
            isDeletedForEver: false,
            changing: this.edtcnting,
            cluster_cnt: this.$store.state.results.nodes_cluster_cnt[this.nid],
            firstChild: false,
            ndType:'',
            diagramIncluded:this.plotdata,
            conceptParams: ''
        };
    },
    methods:{
        // check_exact_math(){
        //     const index = this.nds.indexOf(this.$store.state.results.exact_match);
        //     if(index > -1){
        //         this.nds.splice(index, 1);
        //         this.nds.unshift(this.$store.state.results.exact_match);
        //         console.log(this.ndid,"------------------", this.nds)
        //     }
        // },
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        startEdit(ndedit){
            this.ndedit = !ndedit
        },
        applyEdit(ndedit){
            this.ndedit = !ndedit
            this.$store.state.results.concepts[this.nid] = this.ndlbl

            this.$store.state.syn_results_updated.concepts[this.nid] = this.ndlbl
        },
        addChild(ndchild){
            this.ndchild = !ndchild
        },
        applyChild(ndchild){
            this.ndchild = !ndchild
        },        
        addConcept(addedConcept){
            if(addedConcept !== ''){
                const newId = Object.keys(this.$store.state.results.concepts).length+1;
                this.$store.state.results.concepts[newId] = addedConcept;
                this.$store.state.results.nodes_common[newId] = false
                this.$store.state.results.nodes_deleted[newId] = false
                this.$store.state.results.nodes_shared[newId] = false
                if(this.diagramIncluded){
                    this.$store.state.results.nodes_params[newId] = ""
                }
                this.$store.state.results.nodes_parent[newId] = this.ndid
                this.$store.state.results.tree[this.ndid].push(newId)
                this.$store.state.results.tree[newId] = []
                this.addedConcept = ''


                this.$store.state.syn_results_updated.concepts[newId] = addedConcept;
                this.$store.state.syn_results_updated.nodes_parent[newId] = this.ndid



            }
        },
        addIndentedConcept(indentedConcept){
            if(typeof indentedConcept === 'undefined'){
                alert('Input value is undefined!');
                this.newIndented = false;
                this.ndchild = !this.ndchild
            }else{

                this.newIndented = true;
                const newId = Object.keys(this.$store.state.results.concepts).length+1;
                this.$store.state.results.concepts[newId] = indentedConcept;
                this.$store.state.results.nodes_common[newId] = false
                this.$store.state.results.nodes_deleted[newId] = false
                this.$store.state.results.nodes_shared[newId] = false
                if(this.diagramIncluded){
                    this.$store.state.results.nodes_params[newId] = ""
                }
                this.$store.state.results.nodes_parent[newId] = this.ndid
                this.$store.state.results.tree[this.ndid].push(newId)
                this.$store.state.results.tree[newId] = []
                this.showChildren = true

                this.$store.state.syn_results_updated.concepts[newId] = indentedConcept;
                this.$store.state.syn_results_updated.nodes_parent[newId] = this.ndid

            }
        },
        showConceptPlot(){
            // console.log('hi')
            this.showPlot = true
        },
        deleteConcept(){
            this.isDeleted = true
            this.$store.state.results.nodes_deleted[this.ndid] = true

            this.$store.state.syn_results_updated.nodes_deleted[this.ndid] = true
        },
        reverseConcept(){
            this.isDeleted = false
            this.$store.state.results.nodes_deleted[this.ndid] = false

            this.$store.state.syn_results_updated.nodes_deleted[this.ndid] = false
        },
        deleteConcepForEver(){
            this.isDeletedForEver = true
            this.$store.state.resultsDeleted.push(this.ndid)
            // console.log(this.$store.state.resultsDeleted)
            // this.$store.state.results.nodes_deleted[this.ndid] = true

            this.$store.state.syn_results_updated.synresultsDeleted.push(this.ndid)
        },
        reverseConceptDeleteForEver(){
            this.isDeletedForEver = false
            const index = this.$store.state.resultsDeleted.indexOf(this.ndid);
            this.$store.state.resultsDeleted.splice(index, 1)
            // console.log(this.$store.state.resultsDeleted)
            // this.$store.state.results.nodes_deleted[this.ndid] = false

            const index2 = this.$store.state.syn_results_updated.synresultsDeleted.indexOf(this.ndid);
            this.$store.state.syn_results_updated.synresultsDeleted.splice(index2, 1)
        },        
        updateGroup(){
            this.$store.commit('updateGroup',this.ndid)
            // console.log(this.$store.state.group)
        },
        updateSharedStatus(){
            this.isShared = !this.isShared
        },
        determine_type(){
            if (this.$store.state.results.nodes_parent[this.ndid] == this.ndid){
                if (this.ndid.substring(0,2) == 'RG'){
                    this.firstChild = true
                    this.ndType = 'Gene'
                }else if(this.ndid.substring(0,2)=='RD'){
                    this.firstChild = true
                    this.ndType = 'Disease'
                }else if(this.ndid.substring(0,2)=='RU'){
                    this.firstChild = true
                    this.ndType = 'UMLS'
                }else if(this.ndid.substring(0,2)=='RT'){
                    this.firstChild = true
                    this.ndType = 'Terms'
                }             
            }
        },
    },
    computed: {
        labelClasses() {
            return { 'has-children': this.nds }
        },
        indent() {
            return { transform: `translate(${this.depth * 20}px)` }
        },
        indent_twice() {
            return { transform: `translate(${this.depth * 20+15}px)` }
        },
        indent_more() {
            return { transform: `translate(${this.depth * 20+20}px)` }
        },
    },
    // be(){
    //     this.check_exact_math()
    // },
    mounted(){
        this.determine_type()
        
        if(this.diagramIncluded){
            this.coefs = this.$store.state.results.nodes_params[this.nid]
            this.cluster_cnt = this.$store.state.results.nodes_cluster_cnt[this.nid]
        }
    },
    created: function(){
    }
};
</script>
<style>
.icons:hover, .icons:focus {
  text-decoration: none;
  cursor: pointer;
} 
</style>